import { Component, OnInit, ViewChild, HostListener, TemplateRef, ElementRef, Renderer2 } from "@angular/core";
import { ChartService } from "src/app/shared/shared-components/small-line-chart/chart.service";
import { RequestServices } from "src/app/shared/services/request.services";
import { GlobalService } from "src/app/shared/services/global.service";
import { ResellerService } from "src/app/shared/services/reseller.service";

import { webSocket } from 'rxjs/webSocket';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

import { barChartDataOffline, trendChartData } from 'src/app/data/charts';
import { BarChartComponent } from "src/app/components/bootstrap/charts/bar-chart/bar-chart.component";
import { ModalDirective } from 'ngx-bootstrap/modal';


@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  TrendsModal: BsModalRef;

  @ViewChild('trendChart') private trendChartModal: BarChartComponent;
  @ViewChild('chartOffline') private notifyAlertModalOffline: BarChartComponent;
  // @ViewChild('eTrappsAlarm') eTrappsAlarm: SmallLineChartComponent
  // @ViewChild('cartridges') cartridges: SmallLineChartComponent
  // @ViewChild('customerService') customerService: SmallLineChartComponent
  // @ViewChild('orderChart') orderChart: SmallLineChartComponent
  // @ViewChild('RewardsPoints') RewardsPoints: SmallLineChartComponent
  @ViewChild('staticModal') staticModal: ModalDirective;

  trendsModalBool = false;
  orderModalRef: BsModalRef;
  staticModalRef: BsModalRef;


  screenwidth: any = 0;
  screenheight: any = 0;
  changescreenwidth: any = 0;
  changescreenheight: any = 0;
  stylecss: any = "col-lg-2 col-xmd-4 col-md-4 ";
  arrowHead = "fa fa-sort";

  dashboardVar = {
    alertLvlObj: {
      isHigh: true,
      isMedium: true,
      isLow: true,
    },

    marginVar: false,
    eTrappAlertsArr: [],
    eTrappAlertsArrFilter: [],
    cartridgeAlertsArr: [],
    etrappStatusArr: [],
    previousEtrappDate: undefined,
    previousCartDate: undefined,
    previousEtrappDateOnInit: undefined,
    previousCartDateOnInit: undefined,
    eTrappSelectedVlaue: undefined,
    cartSelectedVlaue: undefined,
    alertSelectedValue: undefined,
    loadingEtrappAlert: '',
    loadingCartAlert: '',
    alarmPercentage: 0,
    cartHealth: 0,
    etrappHealth: 0,
  }

  dateObj: any = {
    trendsDate: new Date().toISOString().split("T")[0],
    deviceDateTwo: new Date().toISOString().split("T")[0],
  }
  today = new Date().toISOString().slice(0, 16).split("T")[0];

  counsumerVar = this.global.getProObj();
  isFullScreen: any = false;
  showeTrappFullScreen: any = false;
  itemOrder = { label: "All", value: "all" };
  itemOptionsOrders = [
    { label: "8 hours", value: "8hours" },
    { label: "1 day", value: "1day" },
    { label: "7 days", value: "7days" },
    { label: "30 days", value: "30days" },
  ];
  eTrappsAlertOptions = [
    { label: "eTRAPPs in Alert", value: "alert" },
    { label: "All", value: "all" },

  ];
  // smallChartData: any;
  // schartHEtrapp: any;
  // schartLEtrapp: any;
  // schartAEtrapp: any;

  // smallChartData1: any;
  // schartLCart: any;
  // schartHCart: any;
  // schartACart: any;

  // smallChartData2;
  // schartLCustomer: any;
  // schartHCustomer: any;
  // schartACustomer: any;

  // smallChartData3;
  // schartLOrder: any;
  // schartHOrder: any;
  // schartAOrder: any;
  // chartV = false;

  // smallChartData4: any;
  // schartLReward: any;
  // schartHReward: any;
  // schartAReward: any;

  // etrappAlarmsChart: any;
  // cartridgesChart: any;
  // customerServiceChart: any;
  // ordersChart: any;
  // rewardsChart: any;
  pubSubChannel: any = undefined;
  deviceOfflineLength = null;

  barChartDataOffline = barChartDataOffline;
  trendChartData = trendChartData;

  chartDataConfig: ChartService;
  consumerData = true;
  macId: any;
  constructor(

    private chartService: ChartService,
    private services: RequestServices,
    public global: GlobalService,
    private resellerService: ResellerService,
    private modalService: BsModalService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.chartDataConfig = this.chartService;
    // this.etrappAlarmsChart = [];
    // this.cartridgesChart = [];
    // this.customerServiceChart = [];
    // this.ordersChart = [];
    // this.rewardsChart = [];
    // this.chartDataConfig = this.chartService;

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.changescreenwidth = window.innerWidth;
    this.changescreenheight = window.innerHeight;
    var ranger = this.screenwidth - 50;
    if ((
      this.changescreenwidth >= ranger
    )

    ) {
      // document.querySelector(".remover").classList.add("justify-content-between");
      // this.stylecss = "col-2";
      // if ((this.changescreenwidth > 700 && this.changescreenwidth < 1630)) {
      //   document.querySelector(".remover").classList.remove("justify-content-between");
      //   this.stylecss = "col-3";


      //   this.stylecss = "col-3";
      // }
    }

    else if ((this.changescreenwidth > this.screenwidth)

    ) {
      this.screenwidth = this.changescreenwidth;
      this.screenheight = this.changescreenheight;
      this.stylecss = "col-2";
      if ((this.changescreenwidth > 700 && this.changescreenwidth < 1630)) {
        this.stylecss = "col-4";

        this.stylecss = "col-4";
      }
    }
    else if ((this.changescreenwidth < 700)) {
      this.stylecss = "col-6";
    }
    else if ((this.changescreenwidth > 700 && this.changescreenwidth < 1630)) {
      // var savs5 = document.querySelector(".remover").classList.remove("justify-content-between");

      this.stylecss = "col-3";

      this.stylecss = "col-3";
    }

    if (window.innerWidth < 1551 && window.innerWidth > 1215) {
      this.dashboardVar.marginVar = true;

    }
    else {
      this.dashboardVar.marginVar = false;
    }

  }

  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])

  handleKeyboardEvent(event: KeyboardEvent) {
    this.showeTrappFullScreen = !this.showeTrappFullScreen;
    if (!this.showeTrappFullScreen) {
      this.isFullScreen = false
      document.exitFullscreen();
    }
  }
  ngOnInit(): void {

    this.dashboardVar.eTrappSelectedVlaue = this.itemOptionsOrders[3];
    this.dashboardVar.cartSelectedVlaue = this.itemOptionsOrders[3];
    this.dashboardVar.alertSelectedValue = this.eTrappsAlertOptions[0];

    this.getDateOnInit();
    this.onResize(this.changescreenwidth);


    this.loadEtrappAlerts();
    this.loadCartridgeAlerts();
    // this.initSmallCharts();
    // this.getSmallChartData('widgets');
    //this.getChannel();
    this.getEtrappsByCustomerId(this.dateObj.consumerDate);
    this.getEtrappAlets(this.dateObj.deviceDateTwo);


    if (this.resellerService.subsVar === undefined) {
      this.resellerService.subsVar = this.resellerService.invokeFirstComponentFunction.subscribe((name: any) => {
        // this.newFunction(name);
      })
    }
    this.screenwidth = window.innerWidth;
    this.screenheight = window.innerHeight;

  }

  ngAfterViewInit() {
    // this.modal.nativeElement
    console.log('this.trendChartModal', this.trendChartModal);
    console.log('this.notifyAlertModalOffline', this.notifyAlertModalOffline);
  }
  refreshDataForSmallLineCharts() {
    // this.initSmallCharts();
    // this.getSmallChartData('widgets');
  }


  showTrendsModal(etrapp): void {
    this.staticModal.show();
    this.macId = etrapp.macId;
    this.dateObj.trendsDate = new Date().toISOString().split("T")[0];
    this.getEtrappUnitCosmosData(etrapp.macId, this.dateObj.trendsDate);
    
  }
  
  closeTrendsModal() {
    this.staticModal.hide();
  }

  // initSmallCharts() {
  //   this.smallChartData = {
  //     labels: [],
  //     datasets: [
  //       {
  //         label: "",
  //         borderColor: Colors.getColors().themeColor4,
  //         pointBorderColor: Colors.getColors().themeColor1,
  //         pointHoverBackgroundColor: Colors.getColors().themeColor1,
  //         pointHoverBorderColor: Colors.getColors().themeColor1,
  //         pointRadius: 3,
  //         pointBackgroundColor: Colors.getColors().themeColor1,
  //         pointBorderWidth: 0,
  //         pointHoverRadius: 3,
  //         fill: false,
  //         borderWidth: 2,
  //         data: this.etrappAlarmsChart,
  //         datalabels: {
  //           align: "end",
  //           anchor: "end",
  //         },
  //       },
  //     ],
  //   };
  //   this.smallChartData1 = {
  //     labels: [],
  //     datasets: [
  //       {
  //         label: "",
  //         borderColor: Colors.getColors().themeColor4,
  //         pointBorderColor: Colors.getColors().themeColor1,
  //         pointHoverBackgroundColor: Colors.getColors().themeColor1,
  //         pointHoverBorderColor: Colors.getColors().themeColor1,
  //         pointRadius: 3,
  //         pointBackgroundColor: Colors.getColors().themeColor1,
  //         pointBorderWidth: 0,
  //         pointHoverRadius: 3,
  //         fill: false,
  //         borderWidth: 2,
  //         data: this.cartridgesChart,
  //         datalabels: {
  //           align: "end",
  //           anchor: "end",
  //         },
  //       },
  //     ],
  //   };
  //   this.smallChartData2 = {
  //     labels: [],
  //     datasets: [
  //       {
  //         label: "",
  //         borderColor: Colors.getColors().themeColor4,
  //         pointBorderColor: Colors.getColors().themeColor1,
  //         pointHoverBackgroundColor: Colors.getColors().themeColor1,
  //         pointHoverBorderColor: Colors.getColors().themeColor1,
  //         pointRadius: 3,
  //         pointBackgroundColor: Colors.getColors().themeColor1,
  //         pointBorderWidth: 0,
  //         pointHoverRadius: 3,
  //         fill: false,
  //         borderWidth: 2,
  //         data: this.customerServiceChart,
  //         datalabels: {
  //           align: "end",
  //           anchor: "end",
  //         },
  //       },
  //     ],
  //   };
  //   this.smallChartData3 = {
  //     labels: [],
  //     datasets: [
  //       {
  //         label: "",
  //         borderColor: Colors.getColors().themeColor4,
  //         pointBorderColor: Colors.getColors().themeColor1,
  //         pointHoverBackgroundColor: Colors.getColors().themeColor1,
  //         pointHoverBorderColor: Colors.getColors().themeColor1,
  //         pointRadius: 3,
  //         pointBackgroundColor: Colors.getColors().themeColor1,
  //         pointBorderWidth: 0,
  //         pointHoverRadius: 3,
  //         fill: false,
  //         borderWidth: 2,
  //         data: this.ordersChart,
  //         datalabels: {
  //           align: "end",
  //           anchor: "end",
  //         },
  //       },
  //     ],
  //   };
  //   this.smallChartData4 = {
  //     labels: [],
  //     datasets: [
  //       {
  //         label: "",
  //         borderColor: Colors.getColors().themeColor4,
  //         pointBorderColor: Colors.getColors().themeColor1,
  //         pointHoverBackgroundColor: Colors.getColors().themeColor1,
  //         pointHoverBorderColor: Colors.getColors().themeColor1,
  //         pointRadius: 3,
  //         pointBackgroundColor: Colors.getColors().themeColor1,
  //         pointBorderWidth: 0,
  //         pointHoverRadius: 3,
  //         fill: false,
  //         borderWidth: 2,
  //         data: this.rewardsChart,
  //         datalabels: {
  //           align: "end",
  //           anchor: "end",
  //         },
  //       },
  //     ],
  //   };
  // }

  getDateOnInit() {
    this.dashboardVar.previousEtrappDateOnInit = this.previousDate(this.global.localDateToUTC(new Date()), 720);
    this.dashboardVar.previousCartDateOnInit = this.previousDate(this.global.localDateToUTC(new Date()), 720);
  }
  onChangeOrderBy(item, device: number) {
    // this.itemOrder = item;
    // this.changeOrderBy.emit(item);
    // let date = this.global.localDateToUTC(new Date());
    let datelength = new Date().toISOString().length;
    let date = new Date().toISOString().substring(0, datelength);
    this.getTimeDifference(date, item.value, device);
    if (device == 1) {
      this.dashboardVar.eTrappSelectedVlaue = item
      this.loadEtrappAlerts();
    }
    else if (device == 2) {
      this.dashboardVar.cartSelectedVlaue = item
      this.loadCartridgeAlerts();

    }
    else if (device == 3) {
      this.dashboardVar.alertSelectedValue = item
      this.dashboardVar.eTrappAlertsArrFilter = this.dashboardVar.etrappStatusArr.filter(etrapp => {
        return item.value == 'alert' ? (etrapp.waterLevel <= 25 || etrapp.waterLevel >= 100 || etrapp.waterLevel <= 0) : true
      });
    }
  }

  getEtrappAlets(eve) {

    // this.global._show_loader = true;
    this.global.showLoader("#deviceOfflineDiv");
    eve === null ? this.dateObj.deviceDateTwo = new Date().toISOString().split("T")[0] : this.dateObj.deviceDateTwo = this.dateObj.deviceDateTwo;
    eve === null ? eve = new Date().toISOString().split('T')[0] : eve,
      this.services.getRequest(`Customer/GetCustomerDashboardStats?date=${typeof eve === 'string' ? eve : eve.target.value}&consumerID=${this.global.getProObj().id}`).subscribe(
        (response) => {
          this.global.HideLoader("#deviceOfflineDiv")
          if (response.dashboardWidgets.statusCode === "200") {
            var DeviceOfflineArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            response.dashboardWidgets.alertData.map(it => {
              var index = this.barChartDataOffline.labels.findIndex((u: any) => {
                return u.includes(it.onHour.toString());
              });
            
              if (index > -1) {
                DeviceOfflineArr[index] = (DeviceOfflineArr[index] || 0) + it.alert;
              }
            });
            
            this.barChartDataOffline.datasets[0].data = DeviceOfflineArr;
            this.notifyAlertModalOffline.chart.update();
            this.deviceOfflineLength = response.dashboardWidgets.alertData.length;
          }
          else if (response.dashboardWidgets.statusCode == "409") {
            this.global.HideLoader("#deviceOfflineDiv")
            this.barChartDataOffline.datasets[0].data = DeviceOfflineArr;
            this.notifyAlertModalOffline.chart.update();
            // this.global.showNotification("Info", "", "eTRAPP Alerts not found on this Date");
          }
          (error) => {
            // this.global._show_loader = false;
            this.global.HideLoader("#deviceOfflineDiv")
            this.global.showNotification("Error", "", "Something went wrong");
          }
        }
      );
  }
  getTimeDifference(date: any, obj: any, dev: number) {
    // date = date.substring(0, 10);
    let date1: Date = date
    switch (obj) {
      case '4hours':
        if (dev == 1) {
          this.dashboardVar.previousEtrappDate = this.previousDate(date1, 4);
        }
        else {
          this.dashboardVar.previousCartDate = this.previousDate(date1, 4);
        }
        break;
      case '8hours':
        if (dev == 1) {
          this.dashboardVar.previousEtrappDate = this.previousDate(date1, 8);
        }
        else {
          this.dashboardVar.previousCartDate = this.previousDate(date1, 8);
        }
        break;
      case '1day':
        if (dev == 1) {
          this.dashboardVar.previousEtrappDate = this.previousDate(date1, 24);
        }
        else {
          this.dashboardVar.previousCartDate = this.previousDate(date1, 24);
        }
        break;
      case '7days':
        if (dev == 1) {
          this.dashboardVar.previousEtrappDate = this.previousDate(date1, 168);
        }
        else {
          this.dashboardVar.previousCartDate = this.previousDate(date1, 168);
        }
        break;
      case '30days':
        if (dev == 1) {
          this.dashboardVar.previousEtrappDate = this.previousDate(date1, 720);
        }
        else {
          this.dashboardVar.previousCartDate = this.previousDate(date1, 720);
        }
        break;
    }
  }
  previousDate(date: any, hours: number) {
    date = new Date(date);
    let numberOfMiliseconds = date.getTime();
    let addMlSeconds = (hours * 60) * 60 * 1000;
    let newDate = new Date(numberOfMiliseconds - addMlSeconds)
    // return this.global.localDateToUTC(newDate);
    return newDate;
  }
  calmaxandmin(arr) {
    let mn = new maxmin();
    let sum = 0;

    for (let i = 0; i < arr.length; i++) {
      sum += arr[i];
    }
    mn.max = Math.max(...arr);
    mn.min = Math.min(...arr);
    mn.Avrg = sum / arr.length;
    return mn;
  }
  fullScreenClick() {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }
  fullScreenClickWidget(el: HTMLElement) {

    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      el.requestFullscreen();
    } else {
      document.exitFullscreen();
    };
  }

  getChannel() {
    this.services.getRequest(`PubSub/SubscribePubSub`).subscribe(
      (response) => {
        if (!response.Error) {
          let url = response.data;
          // console.log('Message: ', response.msg);
          this.pubSubChannel = webSocket(url);
          this.pubSubChannel.subscribe(
            async (msg: any) => this.refreshDashboard(msg)
            ,
            (err) => console.log(err)
          );
        } else if (response.Error) {
        }
      },
      (error) => {
      }
    );
  }

  refreshDashboard(msg) {
    if (this.global.getProObj().id === msg.ProId) {
      this.loadEtrappAlerts();
      this.loadCartridgeAlerts();
    }

  }

  loadEtrappAlerts() {
    const obj = {
      id: this.global.getProObj().id,
      startFrom: 0,
      endTo: 0,
      startDate: this.dashboardVar.previousEtrappDate == undefined ? this.dashboardVar.previousEtrappDateOnInit : this.dashboardVar.previousEtrappDate,
      endDate: new Date().toISOString(),

    }
    this.global.showLoader("#eTrappAlerts");

    this.dashboardVar.loadingEtrappAlert = "Loading etrapp alerts data..."
    this.services
      .postRequest(`Customer/GetDashboardEtrappAlertsByCustomerId`, obj)
      .subscribe(
        (response) => {
          this.dashboardVar.eTrappAlertsArr = [];
          if (response.statusCode == "200") {

            this.global.HideLoader("#eTrappAlerts")
            this.dashboardVar.eTrappAlertsArr = response.data;


          } else if (response.statusCode == "409") {
            this.global.HideLoader("#eTrappAlerts")

            this.dashboardVar.loadingEtrappAlert = "No Record Found"


          } else {

            this.global.HideLoader("#eTrappAlerts")

          }
        },
        (error) => {

          this.global.HideLoader("#eTrappAlerts")


        }
      );
  }
  loadCartridgeAlerts() {
    const obj = {
      id: this.global.getProObj().id,
      startFrom: 0,
      endTo: 0,
      endDate: new Date().toISOString(),
      startDate: this.dashboardVar.previousCartDate == undefined ? this.dashboardVar.previousCartDateOnInit : this.dashboardVar.previousCartDate,
    }

    this.global.showLoader("#cartAlerts");

    this.dashboardVar.loadingCartAlert = "Loading cartridge alert data...";
    this.services
      .postRequest(`Customer/GetDashboardCartridgeAlertsByCustomerId`, obj)
      .subscribe(
        (response) => {
          this.dashboardVar.cartridgeAlertsArr = []
          if (response.statusCode == "200") {

            this.dashboardVar.cartridgeAlertsArr = response.data
            this.global.HideLoader("#cartAlerts")


          } else if (response.statusCode == "409") {
            // this.global._show_loader = false;
            this.global.HideLoader("#cartAlerts")

            this.dashboardVar.loadingCartAlert = "No record found";

          } else {

            this.global.HideLoader("#cartAlerts")

          }
        },
        (error) => {
          this.global.HideLoader("#cartAlerts")


        }
      );
  }


  // getSmallChartData(flag: any) {
  //   this.global.showLoader("#lineCharts")

  //   this.services
  //     .getRequest(`Customer/GetCustomerDashboardStats?customerId=${this.global.getProObj().id}`)
  //     .subscribe(
  //       (response) => {
  //         if (response.statusCode = 200) {

  //           this.global.HideLoader("#lineCharts")

  //           if (flag == "widgets") {
  //             // this.eTrappsAlarm.chart.destroy();
  //             for (var item in response.dashboardWidgets) {
  //               this.makeChartData(item, response.dashboardWidgets)
  //             }
  //             setTimeout(() => {
  //               $(".chartjs-render-monitor").each(function () { $(this).click() })
  //             }, 100);
  //           }
  //         }
  //         else {
  //           "Something went wrong while fetching consumer data.";
  //         }
  //       },
  //       (error) => {
  //       }
  //     );
  // }
  // makeChartData(item, obj) {
  //   var weekDays = [
  //     { day: "Sunday", count: 0 },
  //     { day: "Monday", count: 0 },
  //     { day: "Tuesday", count: 0 },
  //     { day: "Wednesday", count: 0 },
  //     { day: "Thursday", count: 0 },
  //     { day: "Friday", count: 0 },
  //     { day: "Saturday", count: 0 },
  //   ];
  //   let newArr = [];
  //   let newarr2 = []
  //   switch (item) {
  //     case 'alertData':

  //       if (obj.alertData.length <= 7) {


  //         if (obj.alertData.length !== 0) {

  //           const d = new Date();
  //           let day = d.getDay();

  //           let remDays = weekDays.splice(0, day + 1);
  //           let newArr = remDays.reverse().concat(weekDays.reverse());
  //           newarr2 = newArr.reverse();

  //         }
  //       }

  //       obj.alertData.map((custData: any) => {
  //         newarr2.some((weekDay: any) => {
  //           if (custData.day == weekDay.day) {
  //             weekDay.count = custData.count;
  //             return;
  //           }
  //         })
  //       })
  //       newarr2.some(it => {
  //         this.smallChartData.labels.push(it.day);
  //         this.etrappAlarmsChart.push(it.count);
  //       })
  //       if (obj.alertData.length == 0) {
  //         this.schartHEtrapp = 0;
  //         this.schartLEtrapp = 0;
  //         this.schartAEtrapp = 0;
  //       }
  //       else {
  //         // this.eTrappsAlarm.chart.reset();
  //         this.eTrappsAlarm.chart.update();
  //         var a = this.calmaxandmin(this.etrappAlarmsChart);
  //         this.schartLEtrapp = a.min;
  //         this.schartHEtrapp = a.max;
  //         this.schartAEtrapp = a.Avrg;
  //         this.schartAEtrapp = (Math.round(this.schartAEtrapp * 100) / 100).toFixed(2);
  //       }
  //       break;


  //     case 'cartridgeData':
  //       if (obj.cartridgeData.length <= 7) {
  //         if (obj.cartridgeData.length !== 0) {
  //           const d = new Date();
  //           let day = d.getDay();


  //           let remDays = weekDays.splice(0, day + 1);

  //           let newArr = remDays.reverse().concat(weekDays.reverse());
  //           newarr2 = newArr.reverse();

  //         }

  //       }
  //       obj.cartridgeData.map((custData: any) => {
  //         newarr2.some((weekDay: any) => {
  //           if (custData.day == weekDay.day) {
  //             weekDay.count = custData.count;
  //             return;
  //           }
  //         })
  //       })

  //       newarr2.some(it => {
  //         this.smallChartData1.labels.push(it.day);
  //         this.cartridgesChart.push(it.count);
  //       })

  //       if (obj.cartridgeData.length == 0) {
  //         this.schartHCart = 0;
  //         this.schartLCart = 0;
  //         this.schartACart = 0;
  //       }
  //       else {
  //         this.cartridges.chart.update();
  //         var a = this.calmaxandmin(this.cartridgesChart);
  //         this.schartLCart = a.min;
  //         this.schartHCart = a.max;
  //         this.schartACart = a.Avrg;
  //         this.schartACart = (Math.round(this.schartACart * 100) / 100).toFixed(2);
  //         weekDays.map(it => it.count = 0)
  //       }
  //       break;

  //     case 'customerServiceData':

  //       if (obj.customerServiceData.length <= 7) {
  //         if (obj.customerServiceData.length !== 0) {
  //           const d = new Date();
  //           let day = d.getDay();
  //           let remDays = weekDays.splice(0, day + 1);

  //           newArr = remDays.reverse().concat(weekDays.reverse());
  //           newarr2 = newArr.reverse();
  //         }

  //       }
  //       obj.customerServiceData.map((custData: any) => {
  //         newarr2.some((weekDay: any) => {
  //           if (custData.day == weekDay.day) {
  //             weekDay.count = custData.count;
  //             return;
  //           }
  //         })
  //       })
  //       newArr.some(it => {
  //         this.smallChartData2.labels.push(it.day);
  //         this.customerServiceChart.push(it.count);
  //       })
  //       if (obj.customerServiceData.length == 0) {
  //         this.schartHCustomer = 0;
  //         this.schartLCustomer = 0;
  //         this.schartACustomer = 0;
  //       }
  //       else {
  //         this.customerService.chart.update();
  //         var a = this.calmaxandmin(this.customerServiceChart);
  //         this.schartLCustomer = a.min;
  //         this.schartHCustomer = a.max;
  //         this.schartACustomer = a.Avrg;
  //         this.schartACustomer = (Math.round(this.schartACustomer * 100) / 100).toFixed(2);
  //       }
  //       break;

  //     case 'orderData':
  //       if (obj.orderData.length <= 7) {
  //         if (obj.orderData.length !== 0) {

  //           const d = new Date();
  //           let day = d.getDay();

  //           let remDays = weekDays.splice(0, day + 1);
  //           let newArr = remDays.reverse().concat(weekDays.reverse());
  //           newarr2 = newArr.reverse();

  //         }

  //       }
  //       obj.orderData.map((custData: any) => {
  //         newarr2.some((weekDay: any) => {
  //           if (custData.day == weekDay.day) {
  //             weekDay.count = custData.count;
  //             return;
  //           }
  //         })
  //       })

  //       newarr2.map((it: any) => {
  //         this.smallChartData3.labels.push(it.day);
  //         this.ordersChart.push(it.count);
  //       })
  //       if (obj.orderData.length == 0) {
  //         this.schartLOrder = 0;
  //         this.schartHOrder = 0;
  //         this.schartAOrder = 0;
  //       }
  //       else {
  //         this.orderChart.chart.update();
  //         var a = this.calmaxandmin(this.ordersChart);
  //         this.schartLOrder = a.min;
  //         this.schartHOrder = a.max;
  //         this.schartAOrder = a.Avrg;
  //         this.schartAOrder = (Math.round(this.schartAOrder * 100) / 100).toFixed(2);
  //       }
  //       break;
  //     case 'rewardsData':


  //       if (obj.rewardsData.length <= 7) {
  //         if (obj.rewardsData.length !== 0) {
  //           const d = new Date();
  //           let day = d.getDay();


  //           let remDays = weekDays.splice(0, day + 1);

  //           let newArr = remDays.reverse().concat(weekDays.reverse());
  //           newarr2 = newArr.reverse();
  //         }

  //       }
  //       obj.rewardsData.map((custData: any) => {
  //         newarr2.some((weekDay: any) => {
  //           if (custData.day == weekDay.day) {
  //             weekDay.count = custData.count;
  //             return;
  //           }
  //         })
  //       })

  //       newarr2.map((it: any) => {
  //         this.smallChartData4.labels.push(it.day);
  //         this.rewardsChart.push(it.count);
  //       })
  //       if (obj.rewardsData.length == 0) {
  //         this.schartHReward = 0;
  //         this.schartLReward = 0;
  //         this.schartAReward = 0;
  //       }
  //       else {
  //         this.RewardsPoints.chart.update();
  //         var a = this.calmaxandmin(this.rewardsChart);
  //         this.schartLReward = a.min;
  //         this.schartHReward = a.max;
  //         this.schartAReward = a.Avrg;
  //         this.schartAReward = (Math.round(this.schartAReward * 100) / 100).toFixed(2);
  //         weekDays.map(it => it.count = 0)
  //       }
  //       break;
  //   }
  // }
  getEtrappsByCustomerId(eve) {
    let consumerId = this.global.getProObj().id;
    this.global.showLoader("#eTrappsDiv");
    eve === null ? this.dateObj.consumerDate = new Date().toISOString().split("T")[0] : this.dateObj.consumerDate = this.dateObj.consumerDate;
    eve === null ? eve = new Date().toISOString().split('T')[0] : eve,
      this.dashboardVar.loadingCartAlert = "Loading cartridge alert data...";
    this.services.getRequest(`EtrappUnit/GetEtrappUnitsByConsumerId?consumerId=${consumerId}`)
      .subscribe(
        (response) => {
          // this.dashboardVar.cartridgeAlertsArr = []
          if (response.statusCode == "200") {
            debugger
            this.global.HideLoader("#cartAlerts")
            this.dashboardVar.etrappStatusArr = this.dashboardVar.eTrappAlertsArrFilter= response.data;
            // this.dashboardVar.eTrappAlertsArrFilter = this.dashboardVar.etrappStatusArr.filter(etrapp => {
            //   return etrapp.waterLevel <= 25 || etrapp.waterLevel >= 100 || etrapp.waterLevel <= 0
            // });
          } else if 
          (response.statusCode == "409") {
            this.global._show_loader = false;
            this.global.HideLoader("#cartAlerts")
            this.dashboardVar.loadingCartAlert = "No record found";

          } else {

            this.global.HideLoader("#cartAlerts")

          }
        },
        (error) => {
          // this.global.HideLoader("#cartAlerts")


        }
      );
  }
  getEtrappUnitCosmosData(macId, eve) {
    this.dashboardVar.loadingCartAlert = "Loading cartridge alert data...";
    this.global.showLoader("#consumerDiv");
    eve === null ? this.dateObj.consumerDate = new Date().toISOString().split("T")[0] : this.dateObj.consumerDate = this.dateObj.consumerDate;
    eve === null ? eve = new Date().toISOString().split('T')[0] : eve,
    this.services.getRequest(`Customer/GetEtrappUnitCosmosData?macId=${macId}&date=${typeof eve === 'string' ? eve : eve.target.value}`)
      .subscribe(
        (response) => {
          this.global.HideLoader("#consumerDiv");
          if (response.statusCode == "200") {
            var temperatureArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            var humidityArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            var bmpArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            response.data.map(it => {
              var index = this.trendChartData.labels.findIndex((u: any) => {
                return u.includes(it.hour.toString())
              })
              if (index > -1) {
                temperatureArr[index] = it.temperature;
                humidityArr[index] = it.humidity;
                bmpArr[index] = it.bmp;
              }
            })
            this.trendChartData.datasets[0].data = temperatureArr;
            this.trendChartData.datasets[1].data = humidityArr;
            this.trendChartData.datasets[2].data = bmpArr;
            this.consumerData = true;
            this.trendChartModal.chart.update();
          } else if (response.statusCode == "409") {
            this.dashboardVar.loadingCartAlert = "No record found";
            this.trendChartData.datasets[0].data = temperatureArr;
            this.trendChartData.datasets[1].data = bmpArr;
            this.consumerData = false;
            this.trendChartModal.chart.update();
          }
          console.log('temperatureArr', temperatureArr)
          console.log('humidityArr', humidityArr)
          console.log('bmpArr', bmpArr)
        },
        (error) => {
          this.global.HideLoader("#consumerDiv");
          this.global.showNotification("Error", "", "Something went wrong");
        }
      );
  }
  openOrderModal(orderModal: TemplateRef<any>) {
    this.global.modalConfig.class = "modal-md modal-dialog-centered";
    this.orderModalRef = this.modalService.show(
      orderModal,
      this.global.modalConfig
    );
  }
  closeOrderModal() {
    this.orderModalRef.hide();
  }

  quantity = 1;

  increaseQuantity() {
    this.quantity++;
  }

  decreaseQuantity() {
    if (this.quantity > 1) {
      this.quantity--;
    }
  }
  orderCartridge() {
    let obj = {

      "id": 0,
      "consumerId": this.counsumerVar.id,
      "orderQty": this.quantity,
      "orderedOn": new Date(),
      "firstname": this.counsumerVar.firstname,
      "lastname": this.counsumerVar.lastname,
      "email": this.counsumerVar.email,
      "phoneNumber": this.counsumerVar.phoneNumber,
      "address1": this.counsumerVar.address1,
      "address2": this.counsumerVar.address2,
      "cityName": this.counsumerVar.cityName,
      "countryName": this.counsumerVar.countryName,
      "supportEmail": this.counsumerVar.supportEmail,
    }
    this.services.postRequest(`Order/AddOrder`, obj).subscribe(
      (response) => {
        this.global._show_loader = true;
        if (response.statusCode == "201") {
          this.global._show_loader = false;
          this.closeOrderModal();
          this.global.showNotification('Success', '', 'Order placed successfully');

        } else if (response.statusCode == "409") {
          this.global._show_loader = false;


        } else {
          this.global._show_loader = false;

        }
      },
      (error) => {


      }
    );
  }
  waterLevelText(level) {
    switch (true) {
      case level >= 100:
        return 'Condensate level is above normal, contact pro.';

      case level <= 0:
        return 'eTRAPP is dry, run your A/C to create water barrier in eTRAPP.';

      case level <= 25:
        return 'Condensate level is below normal, run AC to refill.';

      default:
        return 'Normal';
    }
  }
}

export class maxmin {
  max: number = 0;
  min: number = 0;
  Avrg: number = 0;
}

