<nav class="navbar fixed-top">
  <div class="d-flex align-items-center navbar-left">
    <a (click)="
              menuButtonClick(
                $event,
                sidebar.menuClickCount,
                sidebar.containerClassnames
              );
              (false)
            " href="javascript:;" class="menu-button d-none d-md-block">
      <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
        <rect x="0.48" y="0.5" width="7" height="1" />
        <rect x="0.48" y="7.5" width="7" height="1" />
        <rect x="0.48" y="15.5" width="7" height="1" />
      </svg>
      {{ " " }}
      <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
        <rect x="1.56" y="0.5" width="16" height="1" />
        <rect x="1.56" y="7.5" width="16" height="1" />
        <rect x="1.56" y="15.5" width="16" height="1" />
      </svg>
    </a>
    <a (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)" href="javascript:;"
      class="menu-button-mobile d-xs-block d-sm-block d-md-none">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
        <rect x="0.5" y="0.5" width="25" height="1" />
        <rect x="0.5" y="7.5" width="25" height="1" />
        <rect x="0.5" y="15.5" width="25" height="1" />
      </svg>
    </a>
    <span class="text-small text-muted mr-2 mb-1">Your pro is</span>
    <h4 class=" text-primary mr-1 mt-2 c-pointer" (click)="editWholesaler(topNavVar.proId)">{{topNavVar.proName
      ===
      undefined ? '':
      topNavVar.proName}}
      <span class=" d-block text-dark" style="font-size: .8rem;">{{topNavVar.proPhoneNumer}}</span>
    </h4>
    <i class="simple-icon-note mr-5 mt-2 text-primary c-pointer" (click)="editWholesaler(topNavVar.proName)">Select Pro</i>
  </div>
  <div class="text-center">
    <a class="navbar-logo" [routerLink]="['/dashboard']"><span class="logo d-none d-xs-block"></span><span
        class="logo-mobile d-block d-xs-none"></span>
    </a>
    <ol class="breadcrumb hider" style="font-size: 8px">
      <li class="breadcrumb-item ng-star-inserted">
        <a ng-reflect-router-link="/dashboard"><br /></a>
      </li>
    </ol>
  </div>
  <div class="navbar-right ">
    <div class="header-icons d-flex align-middle justify-content-end">
      <div class="d-none d-md-inline-block align-text-bottom ml-5">
        <div class="">
          <h3 class="text-primary mt-0 mb-0 right-align mr-3">
            {{proObj.name}}
            <span class="text-dark " style="font-size: .8rem;">Customer ID: {{proObj.id}}</span>
          </h3>
          <ol class="breadcrumb d-flex  " style="font-size: 8px; flex-wrap: nowrap;">
            <li class="breadcrumb-item ng-star-inserted  ">
              <b class="text-left">{{proObj.address1}} {{proObj.cityName}},{{proObj.stateName}},{{proObj.zipCode}}</b>
            </li>
            <li class="breadcrumb-item ng-star-inserted">
              <a ng-reflect-router-link="/app">System status &nbsp;&nbsp;<span class="fa fa-wifi"
                  style="color: green"></span></a>
            </li>
          </ol>
        </div>
      </div>
      <div class="user d-inline-block mr-0 " dropdown>
        <button class="btn btn-empty p-0 mb-2 mr-5 " type="button" dropdownToggle>
          <img alt="Profile Picture" src="/assets/img/profile-pic.jpg" />    
        </button>
        <div class="dropdown-menu dropdown-menu-right mt-0" *dropdownMenu role="menu">
          <li class="divider dropdown-divider"></li>
          <a class="dropdown-item c-pointer" (click)="logoutuser()">Sign out</a>
        </div>
      </div>
    </div>
    
  </div>
</nav>

<ng-template #addWholesaler>
  <div class="child-modal">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Select Pro</h4>
      <button *ngIf="this.topNavVar.proId!== null" type="button" class="close pull-right" aria-label="Close"
        (click)="closeAddWholesalerModal()">
        <span aria-hidden=" true">&times;</span>
      </button>
    </div>

    <form [formGroup]="addWholesalerForm" novalidate (ngSubmit)="submitAddWholesaler(addWholesalerForm.value)">
      <div class="modal-body">
        <div class="row">
          <div class="form-grop col-12">
            <label class="floating-label">Search By Name or PhoneNumber</label>
            <input type="text" class="form-control" formControlName="proSearch" placeholder="Search By Name and PhoneNumber" autocomplete="name" required />
          </div>
            <p style="margin-left: 220px; margin-top: 1rem !important;">OR</p>
          <div class="form-group col-12">
            <label>Search Pro by distance</label>
            <select class="form-control" formControlName="name">
              <option selected disabled hidden value="null">Select Distance</option>
              <option value= "5" >Within 5 miles</option>
              <option value= "10" >Within 10 miles</option>
              <option value= "25">Within 25 miles</option>
              <option value= "50">Over 50 miles</option>
            </select>
            <button type="button" class="btn btn-sm btn-outline-primary float-right mt-2" (click)="GetPro()">Search</button>
          </div>
          <div class="form-group col-12" *ngIf="proList?.length > 0">
            <label><b>Select Pro</b></label>
            <div *ngIf="proList.length > 0">
              <div class="row">
                <div class="col-md-12" style="border: 1px solid rgb(207, 205, 205); border-radius: 5px; max-height: 100px ;overflow: auto;">
                  <div *ngFor="let item of proList"  >
                    <label class="form-check-label ml-3">
                      
                      <input
                        class="form-check-input"
                        type="radio"
                        name="proId"
                        [value]="item.id"
                        formControlName="proId"
                      />
                      {{ item.proName }}  
                    </label>
                    

                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="proList.length === 0">
              No Pro Found
            </div>
          </div>
          
          
        </div>
      </div>
      <div class="modal-footer">
          <button class="btn btn-outline-primary" type="submit" *ngIf="proList?.length > 0">Save & Change</button>
        
        
        <button *ngIf="this.topNavVar.proId!== null" class="btn btn-outline-danger" type="button"
          (click)="closeAddWholesalerModal()">
          Cancel
        </button>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #instructions>
  <div class="child-modal">
    <div class="modal-header">
      <h4 class="modal-title pull-left">
        <!-- <i class="fa fa-file-text-o fa-lg font-weight-bold  mr-1" aria-hidden="true"> </i> -->
        <span>
          <img src="assets/img/eTrappLogoDesignFINAL.svg" class=" ml-1 mb-1 w-15" alt="eTRAPP">
          <span>
            Installation Guidelines
          </span>
        </span>
      </h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeInstructionsModal()">
        <span aria-hidden=" true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="form-grop col-12 mx-auto">
          <div class="text-center" style="padding-top: 20px; padding-bottom: 20px;">
            <h5>
              Coming Soon...
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-outline-primary" type="button" (click)="acknowledgeGuidelines()"> I Acknowledge </button>
      <button class="btn btn-outline-danger" type="button" (click)="closeInstructionsModal()">
        Close
      </button>
    </div>
  </div>
</ng-template>

<!-- <ol class="breadcrumb" style="font-size: 8px;">

        <li class="breadcrumb-item ng-star-inserted">
          <a ng-reflect-router-link="/app">Katy, TX 81F</a>
        </li>

        <li class="breadcrumb-item ng-star-inserted">
          <a ng-reflect-router-link="/app">System status:<span class="glyph-icon iconsminds-wifi"
              style="color: green;"></span></a>
        </li>
        <li class="breadcrumb-item ng-star-inserted">
          <a ng-reflect-router-link="/app">Licensed to <b>Agency Name.</b>
 
          </a>
        </li>

        <li class="breadcrumb-item ng-star-inserted">
          <a ng-reflect-router-link="/app"><b>Jon Doe</b><span>Controller</span>

          </a>
        </li>
        <li class="breadcrumb-item ng-star-inserted">
          <a ng-reflect-router-link="/app">trial version:89 days are remaining <b>Last login 4/18/18 9:55am </b><br></a>

        </li>


      </ol> -->
<!-- <span class="logo d-none d-xs-block"></span> -->


<!-- <span class=" d-block d-xs-none">
      
      </span> -->
<!-- <li class="breadcrumb-item ng-star-inserted">
          <a ng-reflect-router-link="/app">Katy, TX 81F</a>
        </li> -->

<!-- class="glyph-icon iconsminds-wifi" -->
<!-- <li class="breadcrumb-item ng-star-inserted">
          <a ng-reflect-router-link="/app"><b>Jon Doe</b><span>Controller</span>

          </a>
        </li> -->
<!-- <li class="breadcrumb-item ng-star-inserted">
          <a ng-reflect-router-link="/app">trial version:89 days are remaining <b>Last login 4/18/18 9:55am </b><br></a>

        </li> -->
<!-- <div class="btn-group ml-2" dropdown *ngIf="!isSingleLang">
        <button dropdownToggle type="button" class="language-button dropdown-toggle btn btn-light btn-sm">
          {{currentLanguage | uppercase}} <span class="caret"></span>
        </button>
        <ul *dropdownMenu class="dropdown-menu" role="menu">
          <li role="menuitem" *ngFor="let item of languages" (click)="onLanguageChange(item)"><a class="dropdown-item"
              href="javascript:;">{{item.label}}</a></li>
        </ul>
      </div> -->

<!-- <a class="btn btn-sm btn-outline-primary ml-2 d-none d-xl-inline-block" href="https://1.envato.market/6NV1b"
        target="_blank">&nbsp;BUY&nbsp;</a> -->
<!-- <p
            class="ml-n4 mb-0 d-none d-xs-none d-md-none d-lg-inline-block m-auto text-center font-weight-bold text-uppercase">
            qonTango
        </p> -->

<!-- <ol class="breadcrumb pt-0">
        <li class="breadcrumb-item ng-star-inserted">
            <a ng-reflect-router-link="/app">Licensed to <b>Agency Name.</b><br><span style="font-size: smaller;">trial version:89 days are remaining</span></a>
        </li>
        <li class="breadcrumb-item ng-star-inserted">
            <a ng-reflect-router-link="/app"><b>Jon Doe</b><span>Controller</span><br><span style="font-size: smaller;">Last login 4/18/18 9:55am </span> </a>
        </li>
    </ol> -->
<!-- <div class="custom-control custom-switch"   
          placement="left">
          <input type="checkbox" class="custom-control-input" id="darkModeSwitch" [checked]="isDarkModeActive"
            (change)="onDarkModeChange($event)">
          <label class="custom-control-label" for="darkModeSwitch"></label>
        </div> -->

<!-- <div class="position-relative d-none d-sm-inline-block">
          <div dropdown>
            <button dropdownToggle type="button" class="header-icon btn btn-empty">
              <i class="simple-icon-grid"></i>
            </button>
            <div *dropdownMenu class="dropdown-menu dropdown-menu-right mt-3" role="menu" aria-labelledby="button-basic"
              id="iconMenuDropdown">
              <a href="javascript:;" class="icon-menu-item">
                <i class="iconsminds-equalizer d-block"></i>
                <span>Settings</span>
              </a>
  
              <a href="javascript:;" class="icon-menu-item">
                <i class="iconsminds-male-female d-block"></i>
                <span>Users</span>
              </a>
  
              <a href="javascript:;" class="icon-menu-item">
                <i class="iconsminds-puzzle d-block"></i>
                <span>Components</span>
              </a>
  
              <a href="javascript:;" class="icon-menu-item">
                <i class="iconsminds-bar-chart-4 d-block"></i>
                <span>Profits</span>
              </a>
  
              <a href="javascript:;" class="icon-menu-item">
                <i class="iconsminds-file d-block"></i>
                <span>Surveys</span>
              </a>
              <a href="javascript:;" class="icon-menu-item">
                <i class="iconsminds-suitcase d-block"></i>
                <span>Tasks</span>
              </a>
            </div>
          </div>
        </div> -->
<!-- <div class="position-relative d-none d-sm-inline-block">
          <div dropdown>
            <button id="button-license" dropdownToggle type="button" id="licenseButton" class="header-icon btn btn-empty">
              <i class="simple-icon-info"></i>
            </button>
            <div id="dropdown-license" *dropdownMenu class="dropdown-menu dropdown-menu-right mt-3" id="license">
              <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                <div class="pl-3">
                  <a href="javascript:;">
                    <p class="font-weight-medium mb-1">Joisse Kaycee just sent a new comment!</p>
                    <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> -->
<!-- <div class="position-relative d-inline-block">
        <div dropdown>
          <button
            id="button-basic"
            dropdownToggle
            type="button"
            id="notificationButton"
            class="header-icon btn btn-empty"
          >
            <i class="simple-icon-bell"></i>
            <span class="count">3</span>
          </button>
          <div
            id="dropdown-basic"
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right mt-1"
            id="notificationDropdown"
          >
            <perfect-scrollbar>
              <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                <a href="javascript:;">
                    <img src="/assets/img/Agency" alt="Notification Image"
                      class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                  </a>
                <div class="pl-3">
                  <a href="javascript:;">
                    <p class="font-weight-medium mb-1">
                      Joisse Kaycee just sent a new comment!
                    </p>
                    <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                  </a>
                </div>
              </div>
              <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                <a href="javascript:;">
                    <img src="/assets/img/notification-thumb.jpg" alt="Notification Image"
                      class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                  </a>
                <div class="pl-3">
                  <a href="javascript:;">
                    <p class="font-weight-medium mb-1">
                      1 item is out of stock!
                    </p>
                    <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                  </a>
                </div>
              </div>
              <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                <a href="javascript:;">
                    <img src="/assets/img/notification-thumb-2.jpg" alt="Notification Image"
                      class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                  </a>
                <div class="pl-3">
                  <a href="javascript:;">
                    <p class="font-weight-medium mb-1">
                      New order received! It is total $147,20.
                    </p>
                    <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                  </a>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
      </div> -->

<!-- <button
        class="header-icon btn btn-empty d-none d-sm-inline-block"
        type="button"
        id="fullScreenButton"
        (click)="fullScreenClick()"
      >
        <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
        <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
      </button> -->
<!--      {{
          displayName.substr(0, 8)
        }} -->

<!-- <a class="dropdown-item c-pointer" (click)="routeToProfile()">Profile</a> -->

<!-- <a class="dropdown-item c-pointer" *ngIf="this.availableFunc|test:['Add']:'License'"
            (click)="openLicenseModal(license)">License</a> -->
<!-- <a
          class="dropdown-item c-pointer"
          (click)="openOrgSettingModal(openOrgSetting)"
          >Organization settings</a
        > -->
<!-- <a class="dropdown-item c-pointer">Feedback & help</a> -->

<!-- demorow -->
<!-- <div class="row" *ngIf="checkroute == true">
  <div class="col-12 ">
    <div class="card ">

        <div class="card-body " style="    padding-top: 0px;
        padding-bottom: 0px;">
            <div class="row">
                <div class="col-md-2 mb-2">
                    <button type="button" style="font-size: 9px;width: 100px;"
                        class="btn btn-xs btn-primary   glyph-icon simple-icon-arrow-left-circle"
                    >Previous</button>

                </div>
                <div class="col-md-2 mb-2">
                    <button type="button" style="font-size: 9px;width: 100px;"
                        class="btn btn-xs btn-primary glyph-icon simple-icon-arrow-right-circle"
                        >Next</button>
                </div>
                <div class="col-md-2 mb-2">
                    <button type="button" style="font-size: 9px;width: 100px;"
                        class="btn btn-xs btn-primary  glyph-icon iconsminds-mail-search">Find</button>
                </div>
                <div class="col-md-2 mb-2">
                    <button type="button" style="font-size: 9px;width: 100px;"
                        class="btn btn-xs btn-primary glyph-icon iconsminds-delete-file">Delete</button>
                </div>
                <div class="col-md-2 mb-2">
                    <button type="button" style="font-size: 9px;width: 100px;"
                        class="btn btn-xs btn-primary glyph-icon iconsminds-printer">Print</button>
                </div>
                <div class="col-md-2 mb-2">
                    <button type="button" style="font-size: 9px;width: 100px;"
                        class="btn btn-xs btn-primary glyph-icon simple-icon-like">Promises</button>
                </div>
                <div class="col-md-2 ">
                    <button type="button" style="font-size: 9px;width: 100px;"
                        class="btn btn-xs btn-primary glyph-icon iconsminds-file">PTC Slip</button>
                </div>
                <div class="col-md-2 ">
                    <button type="button" style="font-size: 9px;width: 100px; white-space: nowrap;"
                        class="btn btn-xs btn-primary glyph-icon iconsminds-old-telephone">Check By Phone</button>
                </div>
                <div class="col-md-2 ">
                    <button type="button" style="font-size: 9px;width: 100px;"
                        class="btn btn-xs btn-primary glyph-icon iconsminds-cash-register-2">Payments</button>
                </div>
                <div class="col-md-2 ">
                    <button type="button" style="font-size: 9px;width: 100px;"
                        class="btn btn-xs btn-primary glyph-icon simple-icon-envelope-letter">Letter
                        History</button>
                </div>

            </div>
        </div>
    </div>
</div>
 </div> -->
<!-- demorow -->