export interface IMenuItem {
  id?: string;
  src?:any;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
}

const data: IMenuItem[] = [
  {
    icon: 'fa fa-tachometer',
    label: 'Dashboard',
    to: '/dashboard',
    src: '../../../assets/images/dashboard-icon.png',

    // subs: [
    //   {
    //     icon: 'simple-icon-briefcase',
    //     label: 'Default',
    //     to: '/app/dashboards/default'
    //   },
    //   {
    //     icon: 'simple-icon-pie-chart',
    //     label: 'Analytics',
    //     to: '/app/dashboards/analytics'
    //   },
    //   {
    //     icon: 'simple-icon-basket-loaded',
    //     label: 'Ecommerce',
    //     to: '/app/dashboards/ecommerce'
    //   },
    //   {
    //     icon: 'simple-icon-doc',
    //     label: 'Content',
    //     to: '/app/dashboards/content'
    //   }
    // ]
  },
  // {
  //   icon: 'fa  fa-money',
  //   label: 'Collection',
  //   to: '',
  //   subs: [
  //     {
  //       icon: 'simple-icon-briefcase',
  //       label: 'Collector-Console Debtor Maintenance',
  //       to: '/collector',

  //     },

  //   ]
  // },
 
  {
    icon: 'fa fa-book',
    label: 'Care Center',
    to: '/customer',
    src: '../../../assets/images/customer-service.png',

  },
  
  {
    icon: 'simple-icon-control-play',
    label: 'Video Tutorials',
    to: '/tutorial',
    src: '',

  },
  {
    icon: 'simple-icon-question',
    label: 'FAQ',
    to: '/faq',
    src: '',

  },
  {
    icon: 'iconsminds-sync',
    label: 'Transfer',
    to: '/transfer',
    src: '',
    subs:[
      {
        icon: 'iconsminds-mail-send',
        label: 'Outgoing Request',
        to: '/transfer',
      },
      {
        icon: 'iconsminds-mail-inbox',
        label: 'Incoming Request',
        to: '/request-received',
      }
    ]

  },
  
  // {
  //   icon: 'simple-icon-control-play',
  //   label: 'Simulate eTrapp',
  //   to: '/simulate',
  //   src: '',

  // },
];
export default data;
