import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SmallLineChartComponent } from './small-line-chart/small-line-chart.component';

@NgModule({
  declarations: [
    SmallLineChartComponent
  ],
  imports: [
    CommonModule,
  ],
  exports:[SmallLineChartComponent]
})
export class SmallLineChartModule { }
