<div class="row">
  <div class="col-12 col-md-12">
    <h1>Dashboard</h1>
    <!-- <div class="top-right-button-container font-weight-bold mb-2">
      <span class="mr-5">Total Customers:<span class="ml-2 text-success">{{dashboardVar.totalCustomers}}</span></span>
      <span class="">Days Since New Customer:<span
          class="ml-2 text-success">{{dashboardVar.daySinceNewCustomer}}</span></span>
    </div> -->
  </div>
</div>
<hr class="" />
<br>
<div class="row">
  <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
    <div class="card custom-height" #eTrappsDiv id="eTrappsDiv">
      <div class="card-body pb-0">
        <div class="float-left float-none-xs">
          <div class="d-inline-block">
            <h4 class="d-inline card-title">eTrapp Status</h4>
          </div>
        </div>

        <div class="float-right float-none-xs">

          <span class="mr-1 font-weight-bold ">Show</span>
          <div class="btn-group " dropdown>
            <button id="button-basic" dropdownToggle type="button"
              class="btn btn-outline-primary btn-xs dropdown-toggle p-2" aria-controls="dropdown-basic">
              {{dashboardVar.alertSelectedValue.label}} <span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem" *ngFor="let item of eTrappsAlertOptions">
                <a (click)="onChangeOrderBy(item,3)" class="c-pointer"
                  [ngClass]="dashboardVar.alertSelectedValue.label === item.label? 'dropdown-item active': 'dropdown-item'">
                  {{ item.label }}
                </a>
              </li>
            </ul>
            <button class="header-icon btn btn-empty d-none d-sm-inline-block p-0 ml-3 mr-4 " type="button"
              id="fullScreenButton" (click)="fullScreenClickWidget(eTrappsDiv)">
              <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
              <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
            </button>
          </div>

          <div class="position-absolute card-top-buttons pl-0 mt-2 ">
            <button class="btn btn-header-light icon-button">
              <i class="simple-icon-refresh"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="card-body mt-0 pt-0 " >
        <perfect-scrollbar class="scroll" [config]="{  wheelPropagation: false, useBothWheelAxes:true }">
          <div class="row align-items-center remover m-4">
            <div class="" *ngFor="let etrapp of dashboardVar.eTrappAlertsArrFilter">
              <div class="card shadow-card" style="max-width: 20rem;">
                <div class="card-header bg-primary d-flex justify-content-center p-0">
                  <div class="align-items-center">
                    <h4 class="m-0 py-2">{{etrapp.deviceName}}</h4>
                  </div>
                </div>
                <div class="card-body " style="max-height: 15rem;">
                  <div class="row">
                    <div class="col-6">
                      <span class="d-block mt-3" title="Water Level {{etrapp.waterLevel}}%"
                        [ngClass]="{'text-danger': etrapp.waterLevel < 25 || etrapp.waterLevel >= 100 || etrapp.waterLevel <= 0}">
                        <img src="assets/images/wave_etrapp.png" width="30px;" height="30px;" />
                        {{etrapp.waterLevel ? etrapp.waterLevel + '%' : '0%'}}
                      </span>
                      <span style="width: 88px;" [ngClass]="{'text-danger':etrapp.remainingCartridgeDays<46}"
                        class="d-block mt-1" title="Cartridge Life {{etrapp.remainingCartridgeDays}} days">
                        <img src="assets/images/cat_etrapp.png" width="30px;" height="30px;" />
                        {{etrapp.remainingCartridgeDays}} days </span>
                      <span class="d-block mt-1" title="Temperature {{etrapp.temparature}} F">
                        <img src="assets/images/temp_etrapp.png" width="30px;" height="30px;" />
                        {{etrapp.temparature ? etrapp.temparature + ' F' : ' 0'}}
                      </span>
                      <span class="d-block mt-1" title="Humidity {{etrapp.humidity}}%">
                        <img src="assets/images/drop_etrapp.png" width="30px;" height="30px;" /> {{etrapp.humidity ?
                        etrapp.humidity + '%' : '0%'}}
                      </span>
                      <span class="d-block mt-1 wifi">
                        <img src="assets/images/{{!etrapp.isOffline? 'online.png' : 'offline.png'}}" width="30px;"
                          height="30px;" /> {{!etrapp.isOffline ? 'Online' : 'Offline'}}
                      </span>
                      <div *ngIf="!etrapp.isOffline" class="invalid-tooltip position-absolute" style="top: 76%;
                              left: 20%;">
                        Use your mobile app to connect your eTrapp!
                      </div>
                      <span class="d-block mt-1">
                        <p class="iconsminds-line-chart-1" style="font-size: 10px !important; cursor: pointer;"
                          (click)="showTrendsModal(etrapp)">Show Trends</p>
                      </span>
                    </div>
                    <div class="col-6">
                      <div class="indicator position-absolute c-pointer"
                        [ngClass]=" (etrapp.waterLevel <= 25 || etrapp.waterLevel >= 100 || etrapp.waterLevel <= 0) ?'indicator-warning':'indicator-success'"
                        style="top: 1rem;left:37%"></div>
                      <img class="img-indicator mt-4"
                        [src]="'assets/images/' + (etrapp.waterLevel <= 0 ? '0' : etrapp.waterLevel <= 25 ? '25' : etrapp.waterLevel <= 50 ? '50' : etrapp.waterLevel <= 75 ? '75' : '100') + '.png'"
                        alt="" style="width: 5.6rem;">
                      <div *ngIf="etrapp.waterLevel <= 25 || etrapp.waterLevel >= 100 || etrapp.waterLevel <= 0"
                        class="invalid-tooltip position-absolute" style="top: 20%;
                            left: 41%;">
                        {{waterLevelText(etrapp.waterLevel)}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer text-center" style="background-color: white;">
                  <span class="fs-6">
                    <b>{{etrapp.etrappAddress}}, {{etrapp.cityName}}, {{etrapp.code}} {{etrapp.zipCode}}</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>

  <div class="col-lg-6 col-md-12  col-sm-12 mt-5">
    <div class="card" #eTrappAlerts id="eTrappAlerts">
      <div class="card-body">
        <div class="float-left float-none-xs">
          <div class="d-inline-block">
            <h4 class="d-inline card-title">eTrapp Alerts</h4>
          </div>
        </div>
        <div class="float-right float-none-xs">
          <span class="mr-1 font-weight-bold ">Show</span>
          <div class="btn-group " dropdown>
            <button id="button-basic" dropdownToggle type="button"
              class="btn btn-outline-primary btn-xs dropdown-toggle p-2" aria-controls="dropdown-basic">
              {{dashboardVar.eTrappSelectedVlaue.label}} <span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem" *ngFor="let item of itemOptionsOrders">
                <a (click)="onChangeOrderBy(item,1)" class="c-pointer"
                  [ngClass]="dashboardVar.eTrappSelectedVlaue.label === item.label? 'dropdown-item active': 'dropdown-item'">
                  {{ item.label }}
                </a>
              </li>
            </ul>
            <button class="header-icon btn btn-empty d-none d-sm-inline-block p-0 ml-3 mr-4 " type="button"
              id="fullScreenButton" (click)="fullScreenClickWidget(eTrappAlerts)">
              <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
              <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
            </button>
          </div>
          <div class="position-absolute card-top-buttons mt-4 mr-3 p-0 pt-1 ">
            <button class="btn btn-header-light icon-button pl-0" (click)="loadEtrappAlerts()">
              <i class="simple-icon-refresh p-0 m-0"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div [ngClass]="isFullScreen==true ? 'fullScreenClass' : 'dashboard-list-with-user' ">
          <perfect-scrollbar class="scroll" [config]="{ suppressScrollX: true, wheelPropagation: false }">
            <table class="table">
              <thead class="lead-th">
                <tr>

                  <th [appSort]="dashboardVar.eTrappAlertsArr" data-order="desc" data-name="deviceName" scope="col"
                    class="c-pointer">eTrapp Device
                    <i [ngClass]="arrowHead" aria-hidden="true" style="font-size: 10px !important;"></i>
                  </th>
                  <th scope="col">Current Alert</th>
                  <th scope="col ">Generated On</th>
                </tr>
              </thead>
              <tbody *ngIf="dashboardVar.eTrappAlertsArr.length> 0; else nodata" style="font-size: 10px">
                <tr *ngFor="let etrapp of dashboardVar.eTrappAlertsArr">

                  <td>
                    {{ etrapp.deviceName}}
                  </td>

                  <td>
                    <img style="width: 20px; height: 20px" src="../../../../assets/images/alert_icon.png" alt="" />
                    <span> {{etrapp.definition}}</span>
                  </td>

                  <td>
                    {{this.global.utcDateToLocal(etrapp.generatedOn)}}
                  </td>

                </tr>
              </tbody>
              <ng-template #nodata>
                <tbody>
                  <tr>
                    <td class="text-center" colspan="4">
                      <p class="card-title">
                        {{dashboardVar.loadingEtrappAlert}}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </ng-template>
            </table>
          </perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
  <br>
  <br>
  <div class="col-lg-6 col-md-12  col-sm-12 mt-5 ">
    <div class="card" #cartAlerts id="cartAlerts">
      <div class="card-body">
        <div class="float-left float-none-xs">
          <div class="d-inline-block">
            <h4 class="d-inline card-title">Cartridge Alerts</h4>
          </div>
        </div>
        <div class="float-right float-none-xs">
          <span class="mr-1 font-weight-bold ">Show</span>
          <div class="btn-group " dropdown>
            <button id="button-basic" dropdownToggle type="button"
              class="btn btn-outline-primary btn-xs dropdown-toggle p-2" aria-controls="dropdown-basic">
              {{dashboardVar.cartSelectedVlaue.label}} <span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem" *ngFor="let item of itemOptionsOrders">
                <a (click)="onChangeOrderBy(item,2)" class="c-pointer"
                  [ngClass]="dashboardVar.cartSelectedVlaue.label === item.label? 'dropdown-item active': 'dropdown-item'">
                  {{ item.label }}
                </a>
              </li>
            </ul>
            <button class="header-icon btn btn-empty d-none d-sm-inline-block p-0 ml-3 mr-4 " type="button"
              id="fullScreenButton" (click)="fullScreenClickWidget(cartAlerts)">
              <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
              <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
            </button>
          </div>
          <div class="position-absolute card-top-buttons mt-4 mr-3 p-0 pt-1 ">
            <button class="btn btn-header-light icon-button pl-0" (click)="loadCartridgeAlerts()">
              <i class="simple-icon-refresh p-0 m-0"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div [ngClass]="isFullScreen==true ? 'fullScreenClass' : 'dashboard-list-with-user' ">
          <perfect-scrollbar class="scroll" [config]="{ suppressScrollX: true, wheelPropagation: false }">
            <div class="d-flex flex-row">
              <table class="table">
                <thead class="lead-th">
                  <tr>
                    <!-- <th [appSort]="dashboardVar.cartridgeAlertsArr" data-order="desc" data-name="customer.name"
                      scope="col" class="c-pointer">Customer
                      <i [ngClass]="arrowHead" aria-hidden="true" style="font-size: 10px !important;"></i>
                    </th> -->
                    <th [appSort]="dashboardVar.cartridgeAlertsArr" data-order="desc" data-name="generatedOn"
                      scope="col">eTrapp Device</th>
                    <th scope="col">Current Alert</th>
                    <th scope="col">Days Left</th>
                    <th scope="col">Installed On</th>
                  </tr>
                </thead>
                <tbody *ngIf="dashboardVar.cartridgeAlertsArr.length>0; else noDataCart" style="font-size: 10px">
                  <tr *ngFor="let cart of dashboardVar.cartridgeAlertsArr">
                    <td>
                      {{cart.deviceName}}
                    </td>
                    <td>
                      <img style="width: 20px; height: 20px" src="../../../../assets/images/alert_icon.png" alt="" />
                      <span> {{cart.definition}}</span>
                    </td>

                    <td>
                      <span [ngClass]="{'text-danger':cart.remainingCartridgeDays<46}"> {{cart.remainingCartridgeDays}}
                        days </span>

                      <span *ngIf="cart.remainingCartridgeDays>46"><u class="c-pointer text-primary pl-1"
                          (click)="openOrderModal(orderModal)">Order
                          Here
                        </u> </span>
                    </td>
                    <td>
                      {{this.global.utcDateToLocal(cart.installedOn)}}
                    </td>
                  </tr>
                </tbody>
                <ng-template #noDataCart>
                  <tbody>
                    <tr>
                      <td class="text-center" colspan="4">
                        <p class="card-title">
                          {{dashboardVar.loadingCartAlert}}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </ng-template>
              </table>
            </div>
          </perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>


  <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
    <div class="card h-100" id="deviceOfflineDiv">
      <div class="card-body">
        <h4 class="card-title">eTRAPPs Alerts</h4>
        <div class="position-absolute card-top-buttons mb-3">
          <input type="date" class="inp1" [value]="dateObj.deviceDateTwo | date: 'yyyy-MM-dd'"
            (change)="getEtrappAlets($event)" [(ngModel)]="dateObj.deviceDateTwo " max={{today}} />
          <button class="btn btn-header-light icon-button ml-2" (click)="getEtrappAlets(null)">
            <i class="simple-icon-refresh"></i>
          </button>
        </div>
        <div class="text-center text-primary font-weight-bold" style="height: 100%;"
          [ngClass]="deviceOfflineLength===0?'d-block':'d-none'">
          No Data Available
        </div>
        <app-bar-chart #chartOffline [options]="chartDataConfig.barChartOptions1" [data]="barChartDataOffline"
          [ngClass]="deviceOfflineLength!==0?'d-block':'d-none'">
        </app-bar-chart>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <!-- <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mt-3">
    <div class="card custom-height" #lineCharts id="lineCharts">
      <div class="card-body pb-0">
        <div class="float-left float-none-xs">
          <div class="d-inline-block">
            <h4 class="d-inline card-title">Performance</h4>
          </div>
        </div>
        <div class="float-right float-none-xs">
          <div class="btn-group" dropdown>
            <button class="header-icon btn btn-empty d-none d-sm-inline-block mr-5 p-0" type="button"
              id="fullScreenButton" (click)="fullScreenClickWidget(lineCharts)">
              <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
              <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
            </button>
          </div>
        </div>
        <div class="position-absolute card-top-buttons pl-0 ml-0 ">
          <button class="btn btn-header-light icon-button" (click)="refreshDataForSmallLineCharts()">
            <i class="simple-icon-refresh"></i>
          </button>
        </div>
      </div>
      <div class="card-body mt-0 pt-0 performance" style="width: 100%; ">
        <perfect-scrollbar class="scroll" [config]="{  wheelPropagation: false, useBothWheelAxes:true }">

          <div class="row mt-4 remover  d-flex justify-content-between" id="remover">
            <div style="width: 50%; ">
              <div class="card mt-1 ">
                <div class="card-body text-center">
                  <span class="card-title">eTRAPPs in Alarm</span>
                  <div class="dashboard-small-chart" [ngClass]="etrappAlarmsChart.length!= 0?'d-block':'d-none'">
                    <app-small-line-chart [count1]="schartHEtrapp" [count2]="schartLEtrapp" [data]="smallChartData"
                      #eTrappsAlarm [options]="chartDataConfig.smallLineChartOptions"></app-small-line-chart>
                  </div>
                  <div class="card-chart-line" [ngClass]="etrappAlarmsChart.length== 0?'d-block':'d-none'">
                    <p class="font-weight-bold position-relative p-nodata">No data to display</p>
                  </div>
                </div>
                <div class="card-footer " style="background-color: white">
                  <div class="row ">
                    <div class="col-7 straightline footerparameter font-weight-bold">Last 7 Days</div>
                    <div class="col-7 straightline footerparameter">Highest Value:</div>
                    <div class="col-2 straightline footervalue">{{ schartHEtrapp }}
                    </div>
                    <div class="col-7 straightline footerparameter">Lowest Value:</div>
                    <div class="col-2 straightline footervalue">{{ schartLEtrapp }}
                    </div>
                    <div class="col-7 straightline footerparameter">Running Avg:</div>
                    <div class="col-3 straightline footervalue">{{ schartAEtrapp}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="width: 50%;">
              <div class="card mt-1 card-chart-line">
                <div class="card-body text-center">
                  <span class="card-title">Cartridges in 4 weeks</span>
                  <div class="dashboard-small-chart" [ngClass]="cartridgesChart.length!= 0?'d-block':'d-none'">
                    <app-small-line-chart [count1]="schartHCart" [count2]="schartLCart" [data]="smallChartData1"
                      #cartridges [options]="chartDataConfig.smallLineChartOptions"></app-small-line-chart>
                  </div>
                  <div class="card-chart-line" [ngClass]="cartridgesChart.length== 0?'d-block':'d-none'">
                    <p class="font-weight-bold position-relative p-nodata">No data to display</p>
                  </div>
                </div>
                <div class="card-footer " style="background-color: white">
                  <div class="row ">
                    <div class="col-7 straightline footerparameter font-weight-bold">Last 7 Days</div>
                    <div class="col-7 straightline footerparameter">Highest Value:</div>
                    <div class="col-2 straightline footervalue">{{ schartHCart }}
                    </div>
                    <div class="col-7 straightline footerparameter">Lowest Value:</div>
                    <div class="col-2 straightline footervalue">{{ schartLCart }}
                    </div>
                    <div class="col-7 straightline footerparameter">Running Avg:</div>
                    <div class="col-3 straightline footervalue">{{ schartACart}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="width: 50%;">
              <div class="card mt-1 card-chart-line">
                <div class="card-body text-center">
                  <span class="card-title">Care Center Calls</span>
                  <div class="dashboard-small-chart" [ngClass]="customerServiceChart.length!= 0?'d-block':'d-none'">
                    <app-small-line-chart [count1]="schartHCustomer" [count2]="schartLCustomer" [data]="smallChartData2"
                      #customerService [options]="chartDataConfig.smallLineChartOptions"></app-small-line-chart>
                  </div>
                  <div class="card-chart-line" [ngClass]="customerServiceChart.length== 0?'d-block':'d-none'">
                    <p class="font-weight-bold position-relative p-nodata">No data to display</p>
                  </div>
                </div>
                <div class="card-footer " style="background-color: white">
                  <div class="row ">
                    <div class="col-7 straightline footerparameter font-weight-bold">Last 7 Days</div>
                    <div class="col-7 straightline footerparameter">Highest Value:</div>
                    <div class="col-2 straightline footervalue">{{ schartHCustomer }}
                    </div>
                    <div class="col-7 straightline footerparameter">Lowest Value:</div>
                    <div class="col-2 straightline footervalue">{{ schartLCustomer }}
                    </div>
                    <div class="col-7 straightline footerparameter">Running Avg:</div>
                    <div class="col-3 straightline footervalue">{{ schartACustomer}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="width: 50%;">
              <div class="card mt-1 ">
                <div class="card-body text-center">
                  <span class="card-title">Orders to be Filled</span>
                  <div class="dashboard-small-chart" [ngClass]="ordersChart.length!= 0?'d-block':'d-none'">
                    <app-small-line-chart [count1]="schartHOrder" [count2]="schartLOrder" [data]="smallChartData3"
                      #orderChart [options]="chartDataConfig.smallLineChartOptions"></app-small-line-chart>
                  </div>
                  <div class="card-chart-line" [ngClass]="ordersChart.length == 0?'d-block':'d-none'">
                    <p class="font-weight-bold position-relative p-nodata">No data to display</p>
                  </div>
                </div>
                <div class="card-footer " style="background-color: white">
                  <div class="row ">
                    <div class="col-7 straightline footerparameter font-weight-bold">Last 7 Days</div>
                    <div class="col-7 straightline footerparameter">Highest Value:</div>
                    <div class="col-2 straightline footervalue">{{ schartHOrder }}
                    </div>
                    <div class="col-7 straightline footerparameter">Lowest Value:</div>
                    <div class="col-2 straightline footervalue">{{ schartLOrder }}
                    </div>
                    <div class="col-7 straightline footerparameter">Running Avg:</div>
                    <div class="col-3 straightline footervalue">{{ schartAOrder}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="width: 50%;">
              <div class="card mt-1 card-chart-line">
                <div class="card-body text-center">
                  <span class="card-title">Reward Points</span>
                  <div class="dashboard-small-chart" [ngClass]="rewardsChart.length!= 0?'d-block':'d-none'">
                    <app-small-line-chart [count1]="schartHReward" [count2]="schartLReward" [data]="smallChartData4"
                      #RewardsPoints [options]="chartDataConfig.smallLineChartOptions"></app-small-line-chart>
                  </div>
                  <div class="card-chart-line" [ngClass]="rewardsChart.length== 0?'d-block':'d-none'">
                    <p class="font-weight-bold position-relative p-nodata">No data to display</p>
                  </div>
                </div>
                <div class="card-footer " style="background-color: white">
                  <div class="row ">
                    <div class="col-7 straightline footerparameter font-weight-bold">Last 7 Days</div>
                    <div class="col-7 straightline footerparameter">Highest Value:</div>
                    <div class="col-2 straightline footervalue">{{ schartHReward }}
                    </div>
                    <div class="col-7 straightline footerparameter">Lowest Value:</div>
                    <div class="col-2 straightline footervalue">{{ schartLReward }}
                    </div>
                    <div class="col-7 straightline footerparameter">Running Avg:</div>
                    <div class="col-3 straightline footervalue">{{ schartAReward}}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div> -->

  </div>


  <ng-template #noData>
    <div style="height:153px;">
      <p>No data to display</p>
    </div>
  </ng-template>


  <ng-template #orderModal>
    <div class="child-modal">
      <div class="modal-header bg-primary">
        <h4 class="modal-title pull-left">Order Cartridge</h4>
        <button type="button" class="close pull-right text-white" aria-label="Close" (click)="closeOrderModal()">
          <span aria-hidden=" true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="quantity-container">
          <span class="quantity">Choose number of cartridges</span>
          <button (click)="decreaseQuantity()" class="quantity-button btn btn-primary">
            <i class="fa fa-minus" aria-hidden="true"></i>

          </button>
          <span class="quantity">{{ quantity }}</span>
          <button (click)="increaseQuantity()" class="quantity-button btn btn-primary">
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="orderCartridge()">
          Place Order
        </button>
        <button class="btn btn-outline-danger" type="button" (click)="closeOrderModal()">
          Cancel
        </button>
      </div>
    </div>
  </ng-template>

  <div class="modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 id="dialog-static-name" class="modal-title pull-left">Indoor Air Quality Trends</h4>
          <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeTrendsModal()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card h-100" id="consumerDiv">
            <div class="card-header mt-3 text-right pb-0">
              <input type="date" class="inp1" [value]="dateObj.trendsDate | date: 'yyyy-MM-dd'"
                (change)="getEtrappUnitCosmosData(macId, $event)" [(ngModel)]="dateObj.trendsDate " max={{today}} />
              <button class="btn btn-header-light icon-button ml-2" (click)="getEtrappUnitCosmosData(macId, null)">
                <i class="simple-icon-refresh"></i>
              </button>
            </div>
            <div class="card-body ">
              <div class="text-center text-primary font-weight-bold" style="height: 100%;"
                [ngClass]="!consumerData ?'d-block':'d-none'">
                No Data Available
              </div>
              <app-line-chart [ngClass]="consumerData ?'d-block':'d-none'" #trendChart
                [options]="chartDataConfig.barChartOptions" [data]="trendChartData">
              </app-line-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>