import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard-component/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { SmallLineChartModule } from 'src/app/shared/shared-components/small-line-chart/small-line-chart.module';
import { SharedDirectivesModule } from 'src/app/shared/directives/shared-directives.modules';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge'
import more from 'highcharts/highcharts-more.src';
import { ComponentsChartModule } from 'src/app/components/bootstrap/charts/components.charts.module';
import { ModalModule } from 'ngx-bootstrap/modal';



export function highchartsModules() {
  // apply Highcharts Modules to this array
  return [more, HighchartsSolidGauge];
}

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    BsDropdownModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    SmallLineChartModule,
    CollapseModule.forRoot(),
    PerfectScrollbarModule,
    SharedDirectivesModule,
    ChartModule,
    ComponentsChartModule,
    ModalModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: highchartsModules, }
  ] ,
  exports:[
    DashboardComponent
  ]
})
export class DashboardModule { }
