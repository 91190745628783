import { Component, OnInit, Renderer2, AfterViewInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { LangService } from 'src/app/shared/lang.service';
import { GlobalService } from 'src/app/shared/services/global.service';
import { environment } from 'src/environments/environment';
import *  as  data from '../../../assets/settings.json';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

@Injectable()
export class AppComponent implements OnInit, AfterViewInit {
  isMultiColorActive = environment.isMultiColorActive;
  constructor(private langService: LangService, private renderer: Renderer2, private global: GlobalService) {

  }

  ngOnInit() {
    this.getScaleTypes();
    this.langService.init();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.renderer.addClass(document.body, 'show');
    }, 1000);
    setTimeout(() => {
      this.renderer.addClass(document.body, 'default-transition');
    }, 1500);
  }
  getScaleTypes() {
    this.global.settings = data['default'];
    let reseller = localStorage.getItem("RESELLER");
    if (reseller === null || reseller === undefined) {
      this.global.name = undefined;
    }
    else {
      this.global.name = reseller
    }
    // this.getJSON("./assets/settings.json")
    //   .subscribe((response) => {
    //     
    //     this.global.settings = response;
    //   }, (error) => {
    //     
    //   });
  }

}
