import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { ISidebar, SidebarService } from '../sidebar/sidebar.service';
import { GlobalService } from '../../services/global.service';
// import { WholesalerService } from '../../services/wholesaler.service'
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { RequestServices } from '../../services/request.services';
@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit {
  @ViewChild('addWholesaler') addWholesalerModal: TemplateRef<any>
  @ViewChild('instructions') instructionsModal: TemplateRef<any>

  wholesalerModalRef: BsModalRef;
  addWholesalerForm: FormGroup;
  instructionsModalRef: BsModalRef;
  proList:[]
  latitude: number;
  longitude: number;
  displayName = localStorage.getItem('UserName');
  sidebar: ISidebar;
  currentLanguage: string;
  isSingleLang;
  isFullScreen = false;
  isDarkModeActive = false;
  searchKey = '';
  subscription: Subscription;
  fileUpdModalRef: BsModalRef;
  fileToUpload: any;
  modalRef: BsModalRef;
  checkroute = false;
  checkdashboard = false;
  wholesalerName = "";
  cTime: any = 'April 18, 2018 9:45am';
  public modalConfig: any = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
  };
  proObj = undefined;
  licenseFile = undefined;
  fileMsg: string = '';
  isLicenseValid: boolean = true;
  fileText: string = "Select License File";
  _top_nav_bar = {
    licenseValidityStr: '',
    _btn_disable: false,
  }
  topNavVar = {
    wholesalers: [],
    proId: undefined,
    proName:null,
    proPhoneNumer:null
  }
  constructor(
    public global: GlobalService,
    private sidebarService: SidebarService,
    private modalService: BsModalService,
    private formBuilder: UntypedFormBuilder,
    private service: RequestServices,

    public router: Router,

  ) {
    this.isDarkModeActive = this.getColor().indexOf('dark') > -1 ? true : false;
  }

  ngOnInit(): void {
    // this.getWholesaler();
    this.initWholesalerForm();
    this.getProByID();
    // this.global.name = this.global.getProObj().proName
    this.subscription = this.sidebarService.getSidebar().subscribe(
      res => {
        this.sidebar = res;
      },
      err => {
        console.error(`An error occurred: ${err.message}`);
      }
    );
    navigator

    this.proObj = this.global.getProObj();
    console.log("proObj in top", this.proObj.wholesalerName);
    this.wholesalerName = this.proObj.wholesalerName;
  }

  getProByID() {
    this.service
      .getRequest(`/Customer/GetProByConsumerID?id=${this.global.getProObj().id}`).subscribe(
        (response) => {
          if (response.status == "200") {
            this.topNavVar.proId = response.proId;
            this.topNavVar.proName = response.proName;
            this.topNavVar.proPhoneNumer = response.phoneNumber;
          } else if (response.statusCode == "409") {

          } else {

          }
        },
        (error) => {
          this.global.showNotification('Error', '', 'Records do not exist');
          this.global._show_loader = false;
        }
      );
  }
  initWholesalerForm() {
    this.addWholesalerForm = this.formBuilder.group({
      id: [this.global.getProObj().id, Validators.compose([Validators.nullValidator])],
      wholesalerId: [0, Validators.compose([Validators.nullValidator])],
      proSearch:[null, Validators.compose([Validators.nullValidator])],
      name: [null, Validators.compose([Validators.required])],
      proId:[null]
    })
  }
  editWholesaler(name: any) {
    this.addWholesalerForm.reset()
    this.topNavVar.wholesalers.find((wholesaler: any) => {
      if (name == wholesaler.name) {

        console.log("wholesaler in top nav dashboard", wholesaler);
        this.addWholesalerForm.controls['wholesalerId'].setValue(wholesaler.id);
        this.addWholesalerForm.controls['name'].setValue(wholesaler.name);
      }
    })
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      });
    } else {
      console.log('Geolocation is not available in this browser.');
    }
    this.openFormModal(this.addWholesalerModal);
  }
  checkWholesaler() {
    console.log("this.global.getProObj()", this.global.getProObj());

    this.topNavVar.proId = this.global.getProObj().wholesalerId
    console.log("this. topNavVar.wholesalerId", this.topNavVar.proId);

    if (this.topNavVar.proId === null) {
      this.openFormModal(this.addWholesalerModal)
    } 
    else {
      this.global.name = this.global.getProObj().wholesalerName
    }
  }
  submitAddWholesaler(obj: any) {
    console.log(obj);
    this.latitude;
    this.longitude;
    console.log('latitide', this.latitude);
    console.log('longitude', this.longitude);
    this.saveWholesaler(obj);
  }
  saveWholesaler(obj) {
    obj.proId;
    this.global._show_loader = true;
    this.service.getRequest(`Customer/UpdateProByConsumerID?consumerID=${this.global.getProObj().id}&proId=${obj.proId}`).subscribe(
      (response) => {
        if (response == 410) {

          this.global._show_loader = false;
        } else if (response == 200) {
          this.closeAddWholesalerModal();
          this.global.showNotification("Success", "", "Pro saved successfully.");
          this.global._show_loader = false;
          this.getProByID();
          // this.global.name = this.addWholesalerForm.value.name
          // localStorage.setItem("WHOLESALER", this.global.name);
          // let newObj = this.global.getProObj();
          // newObj.wholesalerId = this.addWholesalerForm.value.wholesalerId
          // newObj.wholesalerName = this.addWholesalerForm.value.name
          // localStorage.setItem(this.global.settings.PRO_OBJ, btoa(JSON.stringify(newObj)));
          // this.global._show_loader = false;
          // localStorage.setItem("AcknowledgeGuidelines", 'true')

          
        } else {
          this.global.showNotification("Error", "", "Something went wrong.");
          this.global._show_loader = false;
        }
      },
      (error) => {
        this.global.showNotification("Error", "", "Something went wrong.");

        this.global._show_loader = false;
      }
    );
  }
  GetPro(){
    if(this.addWholesalerForm.value.proSearch){
      var query = this.addWholesalerForm.value.proSearch;
      var latlong =this.latitude + "," + this.longitude;
      var distanse = 0;
      var object = {
        search: query,
        ConsumerGPSLocation: latlong
      }
      this.getPros(object);
    }
    else if(this.addWholesalerForm.value.name){
      this.addWholesalerForm.value.proSearch = null;
      var distanse = parseInt(this.addWholesalerForm.value.name);
      var latlongcor = this.latitude + "," + this.longitude;
      var object2 = {
        search: this.addWholesalerForm.get('proSearch').value,
        ConsumerGPSLocation: latlongcor,
        miles: distanse
      }
      this.getPros(object2);

    }
  }
  openFormModal(wholesalerModal: TemplateRef<any>) {
    this.global.modalConfig.class = "modal-md modal-dialog-centered";
    this.wholesalerModalRef = this.modalService.show(
      wholesalerModal,
      this.global.modalConfig
    );
  }
  closeAddWholesalerModal() {
    this.wholesalerModalRef.hide()
    this.proList = []
  }
  getPros(obj){
    if(obj.miles === undefined){
      obj.miles = 0
    }
    //obj.miles ? 0 : obj.miles; 
    this.global._show_loader = true;
    this.service.getRequest(`DistanceProConsumer?search=${obj.search}&consumerGPSLocation=${obj.ConsumerGPSLocation}&miles=${obj.miles}`).subscribe(
      (response) => {
        this.proList = [];
        if(response.statusCode == "200" && response.data !== null) {
          this.proList = response.data;
          this.global._show_loader = false;
          //this.closeInstructionsModal();
        }
        else if(response.statusCode == "200"){
          this.global.showNotification("info", "", "Pro not found.");
          this.addWholesalerForm.reset();
          this.global._show_loader = false;
        }
         else {
          this.global.showNotification("Error", "", "Something went wrong.");
          this.global._show_loader = false;
        }
      },
      (error) => {
        this.global.showNotification("Error", "", "Something went wrong.");

        this.global._show_loader = false;
      }
    );
  }
  closeInstructionsModal() {
    this.instructionsModalRef.hide();
    localStorage.setItem("AcknowledgeGuidelines", 'true')
  }
  acknowledgeGuidelines() {
    this.global._show_loader = true;
    this.service.getRequest(`Pro/IsGuideAcknowledge?ProID=${this.global.getProObj().id}&IsGuideAck=${true}`).subscribe(
      (response) => {
        if (response.statusCode == "200") {

          this.global._show_loader = false;
          this.closeInstructionsModal();
        } else {
          this.global.showNotification("Error", "", "Something went wrong.");
          this.global._show_loader = false;
        }
      },
      (error) => {
        this.global.showNotification("Error", "", "Something went wrong.");

        this.global._show_loader = false;
      }
    );
  }

  fullScreenClick() {

    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  logoutuser() {
    var USERStr = localStorage.getItem(this.global.settings.PRO_OBJ)
    var obj = JSON.parse(atob(USERStr))
    var objid = this.proObj.loginId;

    this.service.postRequest(`UserLogin/Logout?loginID=1`, objid).subscribe(
      (response) => {
        localStorage.removeItem('userCredentials');
        localStorage.removeItem(this.global.settings.ETRAPP_USER);
        localStorage.removeItem("UserName");
        localStorage.removeItem("ID");
        localStorage.removeItem("logo");
        localStorage.removeItem("empID");
        localStorage.removeItem("OrgID");
        localStorage.removeItem("RoleID");
        localStorage.removeItem("uType");
        localStorage.removeItem("thankyouVar");
        this.router.navigate(['/login']);
      },
      (error) => {
        this.global.showNotification('Error', '', 'Something Went Wrong');
      }
    );


  }

  mobileMenuButtonClick = (event: { stopPropagation: () => void; }, containerClassnames: string) => {
    if (event) { event.stopPropagation(); }
    this.sidebarService.clickOnMobileMenu(containerClassnames);
  }

  menuButtonClick = (e: { stopPropagation: () => void; }, menuClickCount: number, containerClassnames: string) => {
    if (e) { e.stopPropagation(); }

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);

    this.sidebarService.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.sidebar.selectedMenuHasSubItems
    );
  }

  getColor() {
    return localStorage.getItem(environment.themeColorStorageKey)
      ? localStorage.getItem(environment.themeColorStorageKey)
      : environment.defaultColor;
  }
  onDarkModeChange(event) {
    let color = this.getColor();
    if (color.indexOf('dark') > -1) {
      color = color.replace('dark', 'light');
    } else if (color.indexOf('light') > -1) {
      color = color.replace('light', 'dark');
    }
    localStorage.setItem(environment.themeColorStorageKey, color);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }


}
