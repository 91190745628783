<div [class]="class">
  <div>
    <p class="lead color-theme-1 mb-1 value">
      {{ currentValue }}
      <span class="text-success" *ngIf="checkflag == true && currentValue != '0'">&#8593;</span>
      <span class="text-danger" *ngIf="checkflag == false">&#8595;</span>
    </p>
    <p class="mb-0 label text-small">
      {{ currentLabel == "-undefined" ? "0-Monday" : currentLabel }}
    </p>
  </div>
  <canvas #chart></canvas>
</div>
